import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { getCursorFromDocumentIndex } from '@prismicio/gatsby-source-prismic-graphql';


const Articulos = (props) => {

  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = props.data.prismic.allFooters.edges[0].node;
  const $layoutData = { $metadata, $footer };



  const limit = 12;
  const didMountRef = useRef(false);
  const [page, setPage] = React.useState(-1);
  const [data, setData] = React.useState(props.data.prismic);
  const [loading, setLoading] = React.useState(false);

  const onPreviousClick = () => setPage(page - limit);
  const onNextClick = () => setPage(page + limit);

  useEffect(() => {

    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    props.prismic
      .load({ variables: { after: getCursorFromDocumentIndex(page) } })
      .then(res => {
        setLoading(false)
        setData(res.data);
      });
  }, [page]);


  var pages = [];

  for (let index = 1; index <= Math.ceil(data.allBlogs.totalCount / limit); index++) {
    pages[index] = index;
  }

  //Articles
  const $articlesList = data.allBlogs.edges;
  
  return (

    <>

      <Layout data={$layoutData} footer={true}  className={'u-articulos'} >


        <SEO metadata={$metadata} title={'Blog'}></SEO>

        <>

          <Helmet>

    
            <style>{`.c-carousel__viewport{margin:0 -28px}.c-carousel{position:relative}.c-article__content{overflow:hidden;border-radius:25px;border:1px solid #e8e8e8;text-align:center}.c-article__image{background-repeat:no-repeat;background-position:center;background-size:cover;position:relative}.c-article__image:before{content:"";display:block;height:0;padding-bottom:66.3%}.c-article__info{padding:20px 20px 23px 20px}.c-article__title{font-size:2rem;font-weight:700;color:#6076e2;margin-bottom:6px}.c-article__description{font-size:1.5rem}.c-article__imagen{display:block;position:relative;background-size:cover;background-position:center;background-repeat:no-repeat;min-height:300px}.c-article__imagen::before{content:"";display:block;height:0;padding-bottom:65%}@media (max-width:374px){.c-home__title{font-size:2.5rem;margin-bottom:31px}.c-carousel__viewport{margin:0 -18px}}@media (min-width:980px){.c-carousel__viewport{margin:0 -36px}}@media (min-width:1200px){.c-article__info{padding:25px 25px 28px 25px}}`}</style>


            <link href={withPrefix('../../assets/css/fixed/screen.css?v=2')} rel="stylesheet" type="text/css" />
            <link href={withPrefix('../../assets/css/sitio/blog.css?v=2')} rel="stylesheet" type="text/css" />


          </Helmet>


          <section className="o-section o-wrapper">
            <h2 className="c-title c-title--blog"><strong>El blog #1</strong> de finanzas personales y educación financiera.</h2>

            {!loading &&
              <ul className="c-blog__list">

                {$articlesList.map((field, i) => {

                  return (


                    <li key={i} className="c-blog__item c-article">
                      <article className="c-article__content">

                      {field.node.imagen && <Link to={`/articulos/${field.node._meta.uid}`} className="c-article__imagen" tabIndex={-1} css={{ backgroundImage: `url(${field.node.imagen.url})` }} title={field.node.titulo ? field.node.titulo[0].text : ""}/>}
                  
                        <div className="c-article__info">
                          <h2 className="c-article__title"><Link to={`/articulos/${field.node._meta.uid}`} >{field.node.titulo[0].text}</Link></h2>
                          {field.node.descripcion && <p className="c-article__description">{field.node.descripcion[0].text}</p>}
                        </div>
                      </article>
                    </li>

                  )
                }
                )}

              </ul>
            }



            {data.allBlogs.totalCount > limit &&
              <div className="c-pager">
                <button disabled={page <= 0} onClick={onPreviousClick} className="c-pager__prev" title="Anterior" />
                {pages.map(item => {
                  return (
                    <button onClick={() => {
                      setLoading(true)
                      item == 1 ? setPage(item - 2) : setPage(item - 1)
                      window.scrollTo(0, 200);

                    }} className={`${item == 1 ? item - 2 == page ? 'c-pager__current' : '' : item - 1 == page ? 'c-pager__current' : ''}`} title={`Ir a página ${item}`}>{item}</button>
                  )
                }
                )}
                <button disabled={!data.allBlogs.pageInfo.hasNextPage} onClick={onNextClick} className="c-pager__next" title="Siguiente" />
              </div>
            }


          </section>


        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query ArticulosQuery ($first: Int = 12, $last: Int, $after: String, $before: String)  {
  prismic {
    allMetadatas  {
      edges {
        node {
          avatar,
          title,
          description,
          keywords,
          url
        }
      }
    },
    allFooters {
      edges {
        node {
          text
        }
      }
    },
    allBlogs (sortBy: fecha_DESC, first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      },
      totalCount
      edges {
        node {
          _meta{
            uid
          }
          descripcion
          imagen
          imagenSharp {
            childImageSharp {
              fluid( quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          titulo
          descripcion
        }
      }
    }, 
  }
}
`


export default Articulos;

